.kontakt-wrapper {
    width: 100%;
    height: 300px;
    align-items: center;
    display: flex;
    justify-content: space-around;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
}

.kontakt-wrapper p {
    font-size: 20px;
}

.kontakt-wrapper h3 {
    margin-bottom: 20px;
    margin-top: 10px;
}
@media only screen and (max-width: 600px) {
    .kontakt-wrapper {
        width: 100%;
        height: 300px;
        align-items: center;
        display: flex;
        justify-content: space-around;
        line-height: 1.7rem;
        font-family: "Open Sans", sans-serif;
        flex-direction: column;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .kontakt-wrapper div {
        width: 100%;
        margin-left: 5px;
    }
}
