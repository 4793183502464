.uebermich-wrapper {
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.image-text-uebermich-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    text-align: justify;
    width: 90%;
}

.uebermich-wrapper strong {
    font-size: 20px;
}

.image-text-uebermich-wrapper img {
    width: 40%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 5px;
    height: 450px;
    object-fit: cover;
}
.image-text-uebermich-wrapper p {
    width: 58%;
}

.text-uebermich-wrapper {
    width: 90%;
    padding: 20px;
    text-align: justify;
    margin-bottom: 100px;
}

.text-uebermich-wrapper h3 {
    margin-bottom: 30px;
}

.eigene-grafik {
    font-size: 8px;
}

.drei-dimensionen {
    height: 400px;
    object-fit: cover;
}

.thankssaying {
    font-weight: bold;
}

.seelfon {
    font-weight: bold;
    color: green;
}

@media only screen and (max-width: 600px) {
    .image-text-uebermich-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        text-align: justify;
        flex-direction: column;
    }
    .image-text-uebermich-wrapper img {
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        border-radius: 5px;
        height: 450px;
        object-fit: cover;
    }

    .image-text-uebermich-wrapper p {
        width: 100%;
    }

    .text-uebermich-wrapper h3 {
        margin-bottom: 30px;
        font-size: 25px;
    }

    .drei-dimensionen {
        width: 100%;
        object-fit: cover;
    }
}

@media only screen and (min-width: 1600px) {
    .uebermich-wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100vw;
    }

    .image-text-uebermich-wrapper {
        width: 70%;
    }
    .text-uebermich-wrapper {
        width: 70%;
    }
}
