.latest-events-wrapper {
    height: 700px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;

    padding-bottom: 100px;
}

.latest-events-text-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 30px;
}

.latest-events-text-wrapper button {
    border: none;
    height: 30px;
    border-radius: 10px;
    width: 20%;
    color: white;
    font-family: "Raleway", sans-serif;
    background-color: #2e4c6d;
    cursor: pointer;
}

h3 {
    margin-bottom: 50px;
    margin-top: 30px;
    font-size: 30px;
    font-family: "Raleway", sans-serif;
    font-weight: 500;
}

.latest-events-container {
    height: 80%;
    display: flex;
    justify-content: space-evenly;
}

.latest-event-wrapper {
    padding: 10px;
    width: 28%;
    height: 100%;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
}

.latest-events-wrapper p {
    font-family: "Raleway", sans-serif;
    margin: 15px 0 15px 0;
}

.latest-event-wrapper img {
    height: 70%;
    width: 100%;
    object-fit: cover;
}

.date-event-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.date-event-wrapper button {
    width: 50%;
    height: 35px;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #2e4c6d;
    font-family: "Raleway", sans-serif;
    cursor: pointer;
}

.date-event-wrapper p {
    font-size: 15px;
}

.event-name-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@media only screen and (max-width: 980px) {
    .latest-events-text-wrapper button {
        width: 40%;
    }
    .latest-events-text-wrapper {
        width: 100vw;
    }

    .latest-events-text-wrapper h3 {
        margin: 0;
    }
    .latest-events-wrapper {
        height: 1300px;
        align-items: center;
        padding: 10px;
    }
    .latest-events-container {
        width: 90vw;
        flex-direction: column;
        margin-top: 40px;
    }

    .latest-event-wrapper {
        width: 100%;
        height: 33%;
        margin-top: 15px;
    }
    .date-event-wrapper {
        padding: 0;
    }
    .date-event-wrapper button {
        padding: 0;
        margin-top: 5px;
    }
}
