/* CSS talk bubble */
.talk-bubble {
    margin: 40px;
    display: inline-block;
    width: 60px;
    height: 40px;
    color: white;
    background-color: #ccca40;
    position: fixed;
    bottom: 5px;
    right: 0px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transition: all 0.2s ease-in;
    z-index: 1000;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.talk-bubble:hover {
    width: 400px;
    height: 80px;
}

.hovered-chat {
    font-size: 10px;
}

.border {
    border: 8px solid #666;
}
.round {
    border-radius: 30px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
}

/*Right triangle, placed bottom right side slightly in*/
.tri-right.border.btm-right:before {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: -8px;
    bottom: -40px;
    border: 20px solid;
    border-color: #666 #666 transparent transparent;
}
.tri-right.btm-right:after {
    content: " ";
    position: absolute;
    width: 0;
    height: 0;
    left: auto;
    right: 9px;
    bottom: -12px;
    border: 6px solid;
    border-color: #ccca40 #ccca40 transparent transparent;
}

/* talk bubble contents */
.talktext {
    text-align: left;
    line-height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.talktext p {
    /* remove webkit p margins */
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
}

@media only screen and (max-width: 600px) {
    .talk-bubble:hover {
        width: 60px;
        height: 40px;
    }

    .hovered-chat {
        font-size: 20px;
    }
}
