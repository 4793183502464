.underconstruction-wrapper {
    padding: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}

.underconstruction-wrapper img {
    height: 75px;
    width: 75px;
    margin-right: 50px;
}

.underconstruction-wrapper div {
    width: 40%;
}

.underconstruction-wrapper h1 {
    margin-bottom: 10px;
}

.underconstruction-wrapper p {
    text-align: justify;
}

.mama {
    margin-top: 30px;
}

@media only screen and (max-width: 600px) {
    .underconstruction-wrapper {
        flex-direction: column;
        font-size: 10px;
    }

    .underconstruction-wrapper h1 {
        font-size: 15px;
    }

    .underconstruction-wrapper div {
        width: 90%;
    }
    .underconstruction-wrapper img {
        height: 75px;
        width: 75px;
        margin: 50px;
    }
}
