.latest-courses-wrapper {
    width: 100vw;
    background-color: #cbbea2;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}

.latest-courses-wrapper h4 {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;
}

.latest-courses-text-wrapper {
    height: 10%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.latest-courses-text-wrapper button {
    border: none;
    height: 30px;
    border-radius: 10px;
    width: 20%;
    color: white;
    background-color: #6b8b54;
    cursor: pointer;
}

.course-description-text {
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}

.latest-courses-container {
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.latest-course-wrapper {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    width: 90%;
    display: flex;
    align-items: center;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.6);
    margin: 10px;
}
.rightside-wrapper-courses {
    width: 70%;
    display: flex;
    flex-direction: column;
}

.latest-course-wrapper img {
    object-fit: cover;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.course-name-wrapper {
    margin-left: 20px;
    height: 90%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;
    overflow: scroll;
}

.course-button-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.course-button-wrapper a {
    border: none;
    text-decoration: none;
    border-radius: 5px;
    margin-right: 20px;
    color: white;
    font-family: "Raleway", sans-serif;
    background-color: #6b8b54;
    cursor: pointer;
    padding: 10px;
}

.aktuelle-wrapper {
    margin-left: 10%;
}

@media only screen and (max-width: 900px) {
    .latest-course-wrapper {
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        width: 95%;
        display: flex;
        align-items: center;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.6);
        margin: 10px;
        flex-direction: column;
    }

    .course-button-wrapper button {
        width: 50%;
    }

    .latest-courses-text-wrapper a {
        width: 40%;
    }

    .course-button-wrapper {
        height: 10%;
        width: 100%;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
    }

    .latest-course-wrapper img {
        width: 100%;
        height: 200px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0px;
    }

    .latest-courses-text-wrapper button {
        width: 30%;
        font-size: 10px;
    }

    .rightside-wrapper-courses {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px;
    }
    .course-name-wrapper div:nth-child(2) {
        font-size: 10px;
    }
    .course-name-wrapper div:nth-child(3) h5 {
        margin-right: 10px;
    }
    .course-name-wrapper div:nth-child(3) p {
        font-size: 12px;
    }

    .rightside-wrapper-courses {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 5px;
        width: 100%;
    }
}

@media only screen and (min-width: 1600px) {
    .latest-course-wrapper {
        width: 70%;
    }
}
