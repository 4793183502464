.huasklaranova-wrapper {
    display: flex;
    flex-direction: column;
    text-align: justify;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}
.huasklaranova-wrapper img {
    width: 100%;
    object-fit: cover;
    height: 80vh;
}

.klaranova-bottom-wrapper {
    display: flex;
    padding: 20px;
    margin-top: 30px;
    justify-content: space-between;
}

.klaranova-bottom-wrapper p {
    width: 60%;
    font-size: 20px;
}
.klaranova-bottom-wrapper div {
    width: 30%;
    display: flex;
    align-items: center;
}

.klaranova-bottom-wrapper ul {
    padding: 30px;
    width: 400px;

    background-color: #cbbea2;
    border-radius: 5px;
    font-size: 16px;
}

.huasklaranova-wrapper h4 {
    margin-bottom: 10px;
}

.huasklaranova-wrapper li {
    margin-bottom: 5px;
}

.construction-klaranova-wrapper {
    display: flex;
    height: 250px;
    width: 100%;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 600px) {
    .huasklaranova-wrapper {
        overflow: hidden;
    }

    .huasklaranova-wrapper img {
        width: 220%;
        object-fit: cover;
        height: 80vh;
        overflow: hidden;
    }

    .klaranova-bottom-wrapper {
        flex-direction: column;
    }

    .klaranova-bottom-wrapper p {
        width: 100%;
        font-size: 20px;
    }

    .klaranova-bottom-wrapper div {
        margin-top: 30px;
        width: 100%;
        display: flex;
        align-items: center;
    }
    .construction-klaranova-wrapper {
        display: flex;
        height: 250px;
        width: 100%;
        align-items: center;
        justify-content: center;
        padding: 5px;
    }
}
