@media only screen and (max-width: 980px) {
    .navbar-wrapper-mobile {
        display: flex;
        width: 100%;
        height: 14vh;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px;
        background-color: #e6e3dc;
        border-bottom: 1px solid white;
        align-items: center;
    }

    .navbar-wrapper-mobile a {
        text-decoration: none;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 20px;
    }

    .logo-image-mobile {
        height: 70%;
    }
}

@media only screen and (min-width: 980px) {
    .navbar-wrapper-mobile {
        display: none;
    }
}
