.booknowbanner-wrapper {
    height: 470px;
    width: 100%;
    position: relative;
    background-color: #6b8b54;
}

.booknowbanner-wrapper img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    filter: brightness(80%);
}

.booknowbanner-text-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    font-family: "Raleway", sans-serif;
    color: white;
    width: 40%;
}

.booknowbanner-text-wrapper p:nth-child(1) {
    font-size: 40px;
}
.booknowbanner-text-wrapper p:nth-child(2) {
    font-size: 20px;
}

.booknowbanner-buttons-wrapper {
    margin-top: 30px;
}

.booknowbanner-buttons-wrapper button:nth-child(1) {
    margin-right: 10px;
    width: 40%;
    background-color: white;
    color: black;
}

.booknowbanner-buttons-wrapper button {
    height: 45px;
    width: 30%;
    border: none;
    border-radius: 50px;
    background-color: transparent;
    border: 2px solid white;
    color: white;
    font-family: "Raleway", sans-serif;
    cursor: pointer;
    font-size: 16px;
}

@media only screen and (max-width: 900px) {
    .booknowbanner-text-wrapper p:nth-child(1) {
        font-size: 40px;
    }
    .booknowbanner-text-wrapper p:nth-child(2) {
        font-size: 12px;
        width: 100%;
        text-align: justify;
    }

    .booknowbanner-buttons-wrapper {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .booknowbanner-buttons-wrapper button {
        width: 50%;
    }

    .booknowbanner-buttons-wrapper button:nth-child(1) {
        width: 100%;
    }

    .booknowbanner-buttons-wrapper button:nth-child(2) {
        width: 80%;
        margin-top: 5px;
    }
}