.ichschaffs-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    font-family: helvetica;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}

.ichschaffs-wrapper div {
    width: 70%;
    text-align: justify;
}

.ichschaffs-button {
    height: 50px;
    width: 250px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    color: white;
    background-color: #327943;
    margin-bottom: 50px;
    margin-top: 50px;
    cursor: pointer;
}

.ichschaffs-wrapper h3 {
    margin: 5px 0 5px 0;
}

.ichschaffs-button-2 {
    height: 50px;
    border: none;
    border-radius: 5px;
    font-size: 15px;
    color: white;
    background-color: #327943;

    cursor: pointer;
    margin-bottom: 80px;
    margin-top: 80px;
}

.ichschaffs-wrapper button:hover {
    background-color: #47ad5f;
    transition: all 0.1s ease-in;
}

.ueberschriften-ichschaffs-wrapper {
    display: flex;
    align-items: baseline;
}

.ueberschriften-ichschaffs-wrapper img {
    height: 15px;
    margin-right: 10px;
}

.ichschaffs-ueberschrift-wrpper {
    display: flex;
    align-items: baseline;
}

.ichschaffs-ueberschrift-wrpper img {
    height: 25px;
}
.ichschaffs-ueberschrift-wrpper h3:nth-child(1) {
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .ichschaffs-wrapper {
        padding: 0;
        margin-top: 50px;
    }

    .ichschaffs-wrapper div {
        width: 95%;
        text-align: justify;
    }
}
