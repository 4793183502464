.carousel-images {
    height: 86vh;
    width: 100%;
    object-fit: cover;
    filter: brightness(80%);
}

.welcome-text {
    left: 10%;
    position: absolute;
    top: 40%;
    font-size: 60px;
    color: white;
    font-family: "Raleway", sans-serif;
    font-weight: 400;
    width: 60%;
}

.welcome-text p:nth-child(1) {
    font-size: 50px;
}
.welcome-text p:nth-child(3) {
    margin-top: 10px;
    font-size: 20px;
}

@media only screen and (max-width: 800px) {
    .welcome-text {
        font-size: 30px;
    }
    .welcome-text p:nth-child(1) {
        font-size: 40px;
    }
    .welcome-text p:nth-child(3) {
        margin-top: 15px;
        font-size: 10px;
    }
}
