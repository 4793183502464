.bottom-wrapper {
    height: 150px;
    width: 100%;
    background-color: #302c10;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-family: "Raleway", sans-serif;
    overflow: hidden;
}

.bottom-wrapper div {
    margin: 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    height: 50%;
}

.bottom-wrapper div a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
}

.bottom-wrapper div:nth-child(3) {
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-family: "Dancing Script", cursive;
    font-size: 40px;
    color: #4e6c50;
}

.copyright-bottom {
    color: white;
    font-size: 10px;
    font-family: "Raleway", sans-serif;
    width: 100%;
}

@media only screen and (max-width: 800px) {
    .bottom-wrapper div {
        margin: 0;
    }
    .bottom-wrapper div:nth-child(1) {
        display: none;
    }
    .bottom-wrapper div:nth-child(3) {
        display: none;
    }
    .bottom-wrapper div {
        width: 50%;
    }
}
