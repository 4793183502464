.TerminPopUp-Wrapper {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: helvetica;
    z-index: 10000000000;
}

.TerminPopUp {
    width: 50%;
    height: 80%;
    background-color: #e6e3dc;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
}

.TerminPopUp div:nth-child(1) {
    padding: 15px;
    display: flex;
    height: 10%;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
}

.TerminPopUp div:nth-child(2) {
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.TerminPopUp div:nth-child(2) h3 {
    width: 80%;
    font-size: 28px;
    margin: 0;
    margin-top: 50px;
    color: #187f3e;
    text-align: justify;
}

.TerminPopUp div:nth-child(2) p {
    width: 80%;
    text-align: justify;
    margin-top: 25px;
    font-size: 16px;
}

.TerminPopUp div:nth-child(3) {
    width: 80%;
    border-top: 0.5px solid black;
    display: none;
}

.TerminPopUp div:nth-child(4) {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.TerminPopUp button {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    cursor: pointer;
    height: 60px;
    width: 80%;
    margin: 5px;
    border: none;
    border-radius: 5px;
    display: inline-block;
    padding: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease;
    background-color: #fff;
    z-index: 1;
    font-size: 18px;
}

.button1-termin:hover {
    background-color: #187f3e;
    color: white;
}

.button1-termin::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #25d366;
    transition: width 0.6s ease;
    z-index: -1;
}

.button1-termin:hover::before {
    width: 100%;
}

.button2-termin:hover {
    background-color: #c6c600;
}

.button2-termin::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;

    background-color: #fdff00;
    transition: width 0.6s ease;
    z-index: -1;
}

.button2-termin:hover::before {
    width: 100%;
}

.button3-termin:hover {
    background-color: #1661a8;
    color: white;
}

.button3-termin::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #2192ff;
    transition: width 0.6s ease;
    z-index: -1;
}

.button3-termin:hover::before {
    width: 100%;
}

.TerminPopUp p:nth-child(1) {
    cursor: pointer;
}

@media only screen and (max-width: 980px) {
    .TerminPopUp {
        width: 95%;
        height: 92%;
        background-color: #e6e3dc;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 5px;
        overflow: scroll;
    }
    .TerminPopUp div:nth-child(3) {
        display: none;
    }
}
