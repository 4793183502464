.hamburger-menu {
    position: relative;
    width: 30px;
    height: 30px;
    cursor: pointer;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}

.menu-button {
    position: absolute;
    top: 0;
    right: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.line {
    width: 100%;
    height: 3px;
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.2s ease-in-out;
    z-index: 2;
    border-radius: 5px;
}

.line:not(:last-child) {
    margin-bottom: 5px;
}

.menu-button.open .line:nth-child(1) {
    transform: translateY(7.9px) rotate(45deg);
}

.menu-button.open .line:nth-child(2) {
    opacity: 0;
}

.menu-button.open .line:nth-child(3) {
    transform: translateY(-7.9px) rotate(-45deg);
}

.menu {
    position: fixed;
    top: 0;
    right: 0;
    width: 0;
    height: 100vh;
    background-color: #edeadf;
    overflow: hidden;
    transition: width 0.3s ease-in-out, height 0.3s ease-in-out;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.menu.open {
    width: 100%;
}

.menu a {
    display: block;
    padding: 20px;
    font-size: 20px;
    color: black;
    text-decoration: none;
}
