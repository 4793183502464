.ersthelfer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}

.unternehmen-angebote-information-wrapper {
    width: 98% !important;
}

.ersthelfer-wrapper h1 {
    margin: 50px;
    font-size: 50px;
    font-weight: 400;
    line-height: 4.5rem;
}
.ersthelfer-wrapper h3 {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: justify;
    width: 80%;
    line-height: 2.5rem;
}

.zussamenarbeit-wrapper {
    display: flex;
    width: 60%;
    align-items: center;
    justify-content: space-around;
    margin: 50px;
}

.zussamenarbeit-wrapper img {
    height: 180px;
    transition: all 1s;
}

.zussamenarbeit-wrapper img:hover {
    transform: scale(120%);
}

.text-wrapper-ersthelfer {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-around;
}

.text-wrapper-ersthelfer h3 {
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.text-wrapper-ersthelfer p {
    text-align: justify;
    font-size: 18px;
}

.text-wrapper-ersthelfer div {
    width: 47.5%;
    margin-top: 20px;
    margin-bottom: 20px;
}

.button-kurse-mhfa {
    height: 50px;
    width: 140px;
    border: none;
    background-color: #327943;
    color: white;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 1s;
}

.button-kurse-mhfa:hover {
    background-color: #6b8b54;
}

.unternehmen-angebote-wrapper {
    margin-top: 100px;
    width: 90%;
    background-color: #327943;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    padding-bottom: 50px;
    line-height: 1;
}

.unternehmen-angebote-wrapper h1 {
    color: white;
    font-size: 60px;
    text-align: center;
}

.unternehmen-image-text-wrapper {
    display: flex;
    background-color: white;
    width: 95%;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
}

.unternehmen-angebote-wrapper img {
    width: 45%;
    object-fit: cover;
    height: 700px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.unternehmen-image-text-wrapper p {
    font-family: helvetica;
    font-size: 25px;
    width: 80%;
    color: #327943;
    font-weight: 500;
    line-height: 1.5;
}

.unternehmen-image-text-wrapper button {
    margin-top: 50px;
    height: 60px;
    width: 260px;
    background-color: #327943;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.unternehmen-image-text-wrapper div {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 55%;
    height: 100%;
}

.autor-quote {
    font-size: 8px;
}

.unternehmen-angebote-information-wrapper h1 {
    margin-left: 0;
}

@media only screen and (max-width: 600px) {
    .ersthelfer-wrapper h1 {
        margin: 50px;
        font-size: 33px;
        font-weight: 400;
    }

    .ersthelfer-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100vw;
        line-height: normal;
        font-family: "Open Sans", sans-serif;
        font-size: 17px;
    }

    .zussamenarbeit-wrapper {
        display: flex;
        width: 60%;
        align-items: center;
        justify-content: space-around;
        margin: 50px;
        flex-direction: column;
    }

    .button-kurse-mhfa {
        margin-top: 20px;
    }

    .ersthelfer-wrapper h3 {
        width: 93%;
    }

    .text-wrapper-ersthelfer {
        flex-direction: column;
        align-items: center;
    }

    .text-wrapper-ersthelfer div {
        width: 93%;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .ersthelfer-wrapper h1 {
        margin: 50px;
        font-size: 33px;
        font-weight: 400;
        margin: 10px 10px 10px 5px;
    }
    .unternehmen-angebote-information-wrapper {
        width: 93% !important;
    }

    .unternehmen-image-text-wrapper {
        flex-direction: column;
    }

    .unternehmen-angebote-wrapper img {
        width: 100%;
        object-fit: cover;
        height: 700px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 5px;
    }

    .unternehmen-image-text-wrapper div {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 10px;
    }

    .unternehmen-angebote-wrapper {
        margin-top: 100px;
        width: 99%;
        background-color: #327943;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 7px;
        padding-bottom: 50px;
        line-height: 1;
    }

    .unternehmen-image-text-wrapper button {
        margin-top: 50px;
        height: 60px;
        width: 260px;
        background-color: #327943;
        color: white;
        font-size: 16px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 50px;
    }

    .zussamenarbeit-wrapper img {
        height: 125px;
        transition: all 1s;
    }
}

@media only screen and (min-width: 1600px) {
    .ersthelfer-fullpage-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
        flex-direction: column;
    }
    .ersthelfer-wrapper {
        max-width: 70vw;
    }
}
