.navbar-wrapper {
    width: 100%;
    height: 14vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: #e6e3dc;
    border-bottom: 1px solid white;
}

.navbar-logo-wrapper {
    margin-left: 25px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Dancing Script", cursive;
    font-size: 30px;
    color: #4e6c50;
}

.navbar-buttons-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.navbar-buttons-wrapper span {
    margin: 8px;
    padding: 8px;
    font-size: 15px;
    font-family: "Raleway", sans-serif;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
}

.navbar-buttons-wrapper span:hover {
    background-color: rgba(255, 255, 255, 0.07);
    transition: all 0.3s;
    color: #4e6c50;
    font-weight: bolder;
    transform: scale(110%);
}

/* .active-navbar-span {
    background-color: rgba(255, 255, 255, 0.07);
    transition: all 0.3s;
    color: #4e6c50;
    transform: scale(110%);
    font-weight: bolder;
} */

.logo-image {
    height: 80%;
    margin-left: 30px;
}

.active {
    text-decoration: underline;
}

@media only screen and (max-width: 980px) {
    .navbar-buttons-wrapper span {
        display: none;
    }

    .navbar-wrapper {
        display: none;
    }
}

@media only screen and (min-width: 1600px) {
    .navbar-buttons-wrapper {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 75%;
    }

    .navbar-buttons-wrapper span {
        font-size: 20px;
    }
}
