.beratungspraxis-page-wrapper {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}

.beratungspraxis-page-wrapper strong {
    font-size: 25px;
}

.beratungspraxis-fotos-wrapper {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 40px;
}

.beratungspraxis-fotos-wrapper img {
    width: 49%;
    height: 450px;
    object-fit: cover;
    border-radius: 5px;
}

.beratungspraxis-text-wrapper {
    width: 90%;
    text-align: justify;
    margin-bottom: 100px;
}

.beratungspraxis-text-wrapper button {
    margin: 40px 0 10px 0;
    height: 50px;
    width: 300px;
    font-size: 18px;
    color: white;
    background-color: #327943;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.beratungspraxis-text-wrapper h3 {
    margin: 35px 0 35px 0;
    font-size: 40px;
}

@media only screen and (max-width: 800px) {
    .beratungspraxis-fotos-wrapper {
        width: 90%;
        display: flex;
        align-items: center;
        flex-direction: column;
        margin: 0;
    }
    .beratungspraxis-fotos-wrapper img {
        width: 100%;
        height: 249px;
        object-fit: cover;
        border-radius: 5px;
        margin-top: 10px;
    }

    .beratungspraxis-text-wrapper h3 {
        margin: 35px 0 35px 0;
        font-size: 30px;
        text-align: left;
    }
}

@media only screen and (min-width: 1600px) {
    .beratungspraxis-fotos-wrapper {
        max-width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 40px;
    }

    .beratungspraxis-text-wrapper {
        max-width: 70%;
        text-align: justify;
        margin-bottom: 100px;
    }
}
