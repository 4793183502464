.praxis-home-wrapper {
    background-color: #edeadf;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.praxis-top-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #edeadf;
    height: 10%;
    width: 80%;
    margin-bottom: 20px;
}

.praxis-top-wrapper h3 {
    margin: 0;
}

.praxis-top-wrapper button {
    border: none;
    height: 30px;
    border-radius: 10px;
    width: 10%;
    margin-right: 10px;
    color: white;
    background-color: #6b8b54;
    cursor: pointer;
}

.praxis-bottom-wrapper {
    background-color: #edeadf;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90%;
    width: 90%;
}

.praxis-bottom-wrapper div {
    display: flex;
}

.praxis-bottom-wrapper img {
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    filter: brightness(90%);
}

.praxis-bottom-wrapper p:nth-child(1) {
    width: 60%;
    text-align: justify;
}

.praxis-bottom-wrapper p:nth-child(2) {
    width: 20%;
}
.praxis-bottom-wrapper p:nth-child(3) {
    width: 20%;
}

.praxis-image-wrapper {
    margin-top: 25px;
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.praxis-text-wrapper {
    margin-top: 20px;
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.praxis-image-wrapper img:nth-child(1) {
    width: 50%;
    height: 500px;
}

.praxis-image-wrapper img:nth-child(2) {
    width: 40%;
    height: 500px;
}

.signature-praxishome {
    width: 95%;
}

.signature-praxishome h3 {
    font-family: "Dancing Script", cursive;
}

@media only screen and (max-width: 800px) {
    .praxis-top-wrapper h3 {
        margin: 0;
        font-size: 20px;
        width: 50%;
    }

    .praxis-top-wrapper button {
        width: 20%;
    }

    .praxis-image-wrapper img:nth-child(1) {
        width: 90%;
    }

    .praxis-image-wrapper img:nth-child(2) {
        display: none;
        width: 20%;
        height: 120%;
    }

    .praxis-image-wrapper img:nth-child(3) {
        display: none;
        width: 40%;
        height: 100%;
    }

    .praxis-bottom-wrapper p:nth-child(1) {
        width: 90%;
    }

    .praxis-bottom-wrapper p:nth-child(2) {
        display: none;
    }

    .praxis-bottom-wrapper p:nth-child(3) {
        display: none;
    }

    .signature-praxishome {
        margin-left: 30px;
    }
}

@media only screen and (min-width: 1600px) {
    .praxis-text-wrapper {
        width: 70%;
    }
    .praxis-image-wrapper {
        width: 75%;
    }
    .signature-praxishome {
        width: 70%;
    }
}
