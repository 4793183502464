.history-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #edeadf;
    overflow: hidden;
    padding-bottom: 100px;
    line-height: 1.7rem;
    font-family: "Open Sans", sans-serif;
    font-size: 17px;
}

.history-wrapper button {
    border: none;
    height: 30px;
    border-radius: 10px;
    width: 10%;
    color: white;
    font-family: "Raleway", sans-serif;
    background-color: #6b8b54;
    cursor: pointer;
}

.history-wrapper div:nth-child(1) {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.history-text-image-wrapper {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    margin-top: 30px;
}

.history-text-image-wrapper div {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.history-text-image-wrapper img {
    width: 100%;
    height: 80%;
    object-fit: cover;
    border-radius: 15px;
    margin-bottom: 50px;
}

.history-text-image-wrapper p {
    width: 40%;
    text-align: justify;
}

.fusszeile-history-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 1;
    margin-top: 20px;
    margin-left: 80px;
}

.breakline-history {
    border-top: 0.5px solid black;
    width: 20%;
    margin-left: 80px;
    margin-top: 80px;
}

@media only screen and (max-width: 800px) {
    .history-text-image-wrapper div {
        width: 100%;
        align-items: center;
    }
    .history-text-image-wrapper img {
        height: 200px;
    }

    .history-wrapper h3 {
        margin-bottom: 50px;
        margin-top: 30px;
        font-size: 15px;
        font-family: "Raleway", sans-serif;
        font-weight: 500;
        width: 50%;
    }
    .history-wrapper button {
        width: 30%;
    }
    .history-text-image-wrapper img {
        width: 90%;
        margin-bottom: 20px;
    }

    .history-text-image-wrapper {
        flex-direction: column-reverse;
        align-items: center;
    }
    .history-text-image-wrapper p {
        width: 90%;
    }

    .breakline-history {
        border-top: 0.5px solid black;
        width: 20%;
        margin-left: 21px;
        margin-top: 46px;
    }
    .fusszeile-history-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 5px;
        line-height: 1;
        margin-top: 20px;
        margin-left: 10px;
    }
}

@media only screen and (min-width: 1600px) {
    .fusszeile-history-wrapper {
        width: 70%;
    }
    .history-wrapper {
        align-items: center;
    }
    .history-text-image-wrapper {
        width: 70%;
    }
    .history-wrapper div:nth-child(1) {
        width: 70%;
    }
}
